export function cleanStr(str){
    //string = string.replace(/[^a-zA-Z0-9]/g, ''); // Only for alphabets and numbers
    //string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); // Only selected characters
    return str.replace(/[&\/\\#$~%'"`:*?<>{}]/g, '');
}

export function cleanStrDash(str){
    //string = string.replace(/[^a-zA-Z0-9]/g, ''); // Only for alphabets and numbers
    //string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); // Only selected characters
    return str.replace(/[^a-zA-Z0-9 &-/()']/g, "");
}

export function cleanPass(str){
    //string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    return str.replace(/[&\/\\$%'"`=:;*?<>{}]/g, '');
    //allowed Charters = ~_!@#^()-+|.,
}


export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }


export function randomString(length,chars) {
    var result = '';
    //var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}


export function convertINRformat(x){
    if(x === null || x === undefined){
        return x;
    }else{
        x=x.toString();
        var afterPoint = '';
        if(x.indexOf('.') > 0)
           afterPoint = x.substring(x.indexOf('.'),x.length);
        x = Math.floor(x);
        x=x.toString();
        var lastThree = x.substring(x.length-3);
        var otherNumbers = x.substring(0,x.length-3);
        if(otherNumbers != '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
        return res;
    }
    
  }