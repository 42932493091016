import React from "react";
import moment from "moment";

export class Pendingitems extends React.Component {
  render() {
    let item = this.props.item;
    return (
      <span >
        <a href="#/">
          
          <span className="from">{item.c_doc_no}</span>
          <time>{moment(item.createdAt).format("DD MMM, YYYY")}</time>
          {/*<span className="subject">{item.subject}</span>
          <span className="msg-body">{item.message}</span>*/}
        </a>
      </span>
    );
  }
}
