import React from "react";
import ToggleShortcut from "./ToggleShortcut";

import { connect } from "react-redux";

class LoginInfo extends React.Component {
  componentWillMount() {}

  render() {
    var user = JSON.parse(localStorage.getItem('user'));
    return (
      <div className="login-info">
        <span>
          <ToggleShortcut>
            <img src={this.props.picture} alt="me" className="online" />
            <span>{user ? user.name : ""/*this.props.username*/}</span>
            <i className="fa fa-angle-down" />
          </ToggleShortcut>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => state.user;

export default connect(mapStateToProps)(LoginInfo);
