import React from "react";
import {smallBox, /*bigBox,*/ SmartMessageBox} from "../../../common/utils/functions/message";
import axios from 'axios';
import {config} from '../../../config/config';
const dbservConfig = {  
  basePath: config.db_url
};

export default class Logout extends React.Component {
  onLogout(e) {
    e.preventDefault();
    var user = JSON.parse(localStorage.getItem('user'));
    SmartMessageBox({
      title: "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'>"+user.name+"</span> ?",
      content: "Are you sure, you want to logout ?",
      buttons: '[No][Yes]'
    }, function (ButtonPressed) {
      if (ButtonPressed === "Yes") {
        //localStorage.removeItem("user");
        //window.location.reload();

        axios.post(dbservConfig.basePath+'/api/auth/logout', {n_user_id: user.id, c_token: user.accessToken}, { headers : {'x-access-token': user.accessToken}})
          .then((result) => {
            if(result.data.result === 'failed'){ 
              smallBox({
                title: "Error",
                content: "<i class='fa fa-clock-o'></i> <i>"+ result.data.message +"</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
              });
            }else if(result.data.result === 'success'){
              localStorage.removeItem("user");
              window.location.reload();
            }
            }).catch(function (error) {
              //console.log(error);
            });

      }
      if (ButtonPressed === "No") {
        smallBox({
          title: "Logout Denied",
          content: "<i class='fa fa-clock-o'></i> <i>You pressed No...</i>",
          color: "#C46A69",
          iconSmall: "fa fa-times fa-2x fadeInRight animated",
          timeout: 4000
        });
      }

    });
  }
  render() {
    return (

          <div id="logout" className="btn-header transparent pull-right">
            <span>
              <a
                href="#/login"
                title="Sign Out"
                onClick={this.onLogout}
              >
                <i className="fa fa-sign-out" />
              </a>
            </span>
          </div>
    );
  }
}
