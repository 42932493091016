import React from "react";

//import API from '../../../apiaxios';
import { Sparkline } from "../../graphs/inline";
import './blink.css';

export default class Stats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tot_act:0,
      tot_act_comp:0,
      ncr_hns_tot: 0,
      sor_hns_tot: 0,
    };
  }

  componentDidMount(){
    console.log("this.props",this.props)
    this.setState({tot_act: this.props.tot_act, tot_act_comp: this.props.tot_act_comp, ncr_hns_tot: this.props.ncr_hns_tot, sor_hns_tot: this.props.sor_hns_tot, userorg: this.props.userorg }); 
    /*
    API.get('/api/custom/selectall', { params: { t: 'pmis_activity_process', cols: 'COUNT(*) as tot' } }) 
        .then( (response) => { 
          this.setState({tot_act: response.data[0].tot}); 
        })
        .catch( (error) => { console.log(error); });

     API.get('/api/custom/selectall', { params: { t: 'pmis_activity_process_completion', cols: 'COUNT(*) as tot' } }) 
        .then( (response) => {            
          this.setState({tot_act_comp: response.data[0].tot}); 
        })
        .catch( (error) => { console.log(error); });
        */
  }

  getStats() {
    return {
      income: {
        total: "171",
        values: [
          1300,
          1877,
          2500,
          2577,
          2000,
          2100,
          3000,
          2700,
          3631,
          2471,
          2700,
          3631,
          2471
        ]
      },
      traffic: {
        total: "45%",
        values: [110, 150, 300, 130, 400, 240, 220, 310, 220, 300, 270, 210]
      },
      orders: {
        total: "2447",
        values: [110, 150, 300, 130, 400, 240, 220, 310, 220, 300, 270, 210]
      }
    };
  }

  render() {
    const className = this.props.className;
    const stats = this.getStats();
    return (
      <div className="col-xs-12 col-sm-5 col-md-5 col-lg-8">
        <ul id="sparks" className={className}>
          <li className="sparks-info">
            <h5 className="m-0">
              Activities
              <span className="txt-color-blue"> {this.state.tot_act}{/*stats.income.total*/}</span>
            </h5>
            {/*<Sparkline
              values={stats.income.values}
              className="txt-color-blue hidden-mobile hidden-md hidden-sm"
            />*/}
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Progress
              <span className="txt-color-purple">
                {this.state.tot_act_comp}{/*stats.traffic.total*/}
              </span>
            </h5>
            {/*<Sparkline
              values={stats.traffic.values}
              className="txt-color-purple hidden-mobile hidden-md hidden-sm"
            />*/}
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Budget
              <span className="txt-color-greenDark">
                {/*stats.orders.total*/} 0
              </span>
            </h5>
            {/*<Sparkline
              values={stats.orders.values}
              className="txt-color-greenDark hidden-mobile hidden-md hidden-sm"
            />*/}
          </li>
          {this.state.userorg <= 2 ?
            <li className="sparks-info">
              <h5 className="m-0">
                NCR (H&S)
                <span className="txt-color-pink">
                <span className="spantext">{this.state.ncr_hns_tot}</span>{/*stats.orders.total*/}
                </span>
              </h5>
            </li>
          :""}
          {this.state.userorg <= 2 ?
            <li className="sparks-info">
              <h5 className="m-0">
                SOR (H&S)
                <span className="txt-color-red">
                <span className="spantext">{this.state.sor_hns_tot}</span>{/*stats.orders.total*/}
                </span>
              </h5>
            </li>
          :""}
        </ul>
      </div>
    );
  }
}
