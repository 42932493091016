import { routes as auth } from "./views/auth";
import { routes as dashboards } from "./views/dashboard";
import { routes as misc } from "./views/misc";
export const routes = [
  ...dashboards,
  ...misc,
];

export const authRoutes = [
  ...auth
];
